import request from '../utils/request'

//登录方法
export function login(username,password,uuid){
    const data={
        username,
        password,
        uuid
    }
    return request({
        url:'/login1',
        method:'post',
        data:data
    })
}