<template>
    <div id="index" ref="bgScreen">
        <div class="bg">
            <dv-loading v-if="loading">Loading...</dv-loading>
            <div v-else class="host-body">
                <!-- 标题 -->
                <div class="top">
                    <h1 align="center">突发传染病事件信息平台</h1>
                </div>
                <div class="body-box">
                    <div class="content-box">
                        <!-- 左上 -->
                        <div class="block">
                            <div class="base-info">
                                <center-left1></center-left1>
                            </div>
                            <div class="outbound">
                                <center-left2></center-left2>
                            </div>
                        </div>
                        <!-- 中上 -->
                        <div class="block">
                            <center></center>
                        </div>
                        <!-- 右上 -->
                        <div class="block">
                            <center-right></center-right>
                        </div>
                    </div>
                    <div class="bottom-box">
                        <!-- 左下 -->
                        <div class="block">
                            <bottom-left></bottom-left>
                        </div>
                        <!-- 下中 -->
                        <div class="block">
                            <bottom-center></bottom-center>
                        </div>
                        <!-- 右下 -->
                        <div class="block">
                            <bottom-right></bottom-right>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { patientList } from '@/api/index.js'
import drawMixinWithIndex from '@/mixins/drawMixinWithIndex.js';
import centerLeft1 from '@/components/centerLeft1';
import centerLeft2 from '@/components/centerLeft2';
import centerRight from '@/components/centerRight';
import bottomLeft from '@/components/bottomLeft';
import bottomRight from '@/components/bottomRight.vue';
import bottomCenter from '@/components/bottomCenter.vue';
import center from '@/components/center.vue';
import { mapState } from 'vuex'

export default {
    data() {
        return {
            loading: true,
        }
    },
    mixins: [drawMixinWithIndex],
    components: { centerLeft1, centerLeft2, centerRight, bottomLeft, bottomRight, bottomCenter, center },
    mounted() {
        this.$store.commit("setCLoadingFlag",false);
        this.getPatientList();
        //监听键盘Esc按键事件
        document.addEventListener('keydown', this.keyBack)
    },
    computed: {
        ...mapState({
            flag: state => state.cLoadingFlag,
        }),
    },
    watch: {
        flag: {
            handler(newData) {
                if (newData) {
                    this.cancelLoading();
                } else {
                    this.enterLoading();
                }
            }
        }
    },
    methods: {
        handleBack() {
            this.$router.replace({ name: 'entraceWBS' });
            document.removeEventListener('keydown', this.keyBack);
        },
        keyBack: function (e) {
            console.log(e);
            let self = this;
            let key = e.key || e.keyCode;
            if (key == 'Escape' || key == 27) {
                self.handleBack();
            }
        },
        getPatientList() {
            patientList().then(resp => {
                if (resp.code==200) {
                    let list = resp.data.map((item,index,arr)=>{
                        return {
                            key:index+1,
                            value:item,
                            label:item,
                        }
                    });
                    this.$store.commit('setCLoadingFlag', true);
                    this.$store.commit('setUserList',list);
                }else{
                    this.$store.commit('setCLoadingFlag',false);
                    this.$message.error(resp.msg);
                }
            }).catch(err=>{
                console.log(err);
            })
        },
        cancelLoading() {
            setTimeout(() => {
                this.loading = false
            }, 500)
        },
        enterLoading() {
            setTimeout(() => {
                this.loading = true
            }, 500)
        },
    }
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/index.scss';
</style>