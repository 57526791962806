<template>
    <div class="d-flex jc-center">
        <CenterChart :cdata="mainPositionList"></CenterChart>
    </div>
</template>

<script>
import CenterChart from './echarts/center';
import { mapState } from 'vuex';

export default{
    data(){
        return{
            
        }
    },
    components:{
        CenterChart
    },
    computed: {
        ...mapState({
            mainPositionList: state => state.mainPositionList,
        }),
    },
}
</script>

<style>

</style>