import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import Element from 'element-ui'
//import enLang from 'element-ui/lib/locale/lang/en'// 如果使用中文语言包请默认支持，无需额外引入，请删除该依赖
import 'element-ui/lib/theme-chalk/index.css'
//引入全局样式
import '@/assets/styles/style.scss'
Vue.use(Element, {
  //locale: enLang // 如果使用中文，无需设置，请删除
})

// 按需引入vue-awesome图标
import Icon from 'vue-awesome/components/Icon';
import 'vue-awesome/icons/chart-bar.js';
import 'vue-awesome/icons/chart-area.js';
import 'vue-awesome/icons/chart-pie.js';
import 'vue-awesome/icons/chart-line.js';
import 'vue-awesome/icons/align-left.js';
import dataV from '@jiaminghi/data-view';

Vue.component('icon', Icon);
Vue.use(dataV);

//引入echart
//4.x 引用方式
import echarts from 'echarts'
//5.x 引用方式为按需引用
//希望使用5.x版本的话,需要在package.json中更新版本号,并切换引用方式
//import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts

//引入百度地图
import BaiduMap from '@/components/vue-baidu-map/components'

Vue.use(BaiduMap,{
  ak:'UYITsWKEBA6l5MuxVeMrXk4Sqp9YzH26'
})

Vue.config.productionTip = false


//判断是否有token,没有则登陆
router.beforeEach((to,from,next)=>{
	if(to.path=='/'){
		next();
		return;
	}
	if(to.path!='/login'){
		let token=sessionStorage.getItem('token');
		if(token==null){
			next({path:'/login'});
		}else{
			next();
		}
	}else{
		next();
	}
})

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
