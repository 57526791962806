<template>
    <div class="box2">
        <div class="tr">
            <div class="tr-item">User ID</div>
            <div class="tr-item">感染概率</div>
            <div class="tr-item">风险信息</div>
        </div>

        <div class="table-body">
            <div class="td" v-for="item in probAbilityList" :key="item.id">
                <div class="td-item">{{ item.id }}</div>
                <div class="td-item">{{ item.riskRate }}</div>
                <div class="td-item">
                    <span v-if="item.riskRate < 0.3" style="color: #5ad7d8">轻度易感</span>
                    <span v-else-if="item.riskRate >= 0.3 && item.riskRate < 0.8" style="color: #fbed43">中度易感</span>
                    <span v-else style="color: #f95d49">重度易感</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    data() {
        return {
            fileList: [],
        }
    },
    computed: {
        ...mapState({
            probAbilityList: state => state.probAbilityList,
        }),
    },
}
</script>

<style lang="less" scoped>
.box2 {
    width: 400px;
    padding: 20px;

    .tr {
        display: flex;
        width: 100%;
        color: #fff;
        background: #31728a;
        border-radius: 10px;
        padding: 10px;

        .tr-item {
            width: 33%;
            text-align: center;
        }
    }

    .table-body {
        margin: 0 5px 0 10px;
        height: 350px;
        overflow-y: auto;
        background: linear-gradient(to bottom, #295d77, transparent);

        &::-webkit-scrollbar {
            width: 6px;
            height: 100%;
        }

        &::-webkit-scrollbar-track {
            /* 外层轨道 */
            border-radius: 10px;
        }

        &::-webkit-scrollbar-track-piece {
            /*内层轨道，滚动条中间部分 */
            border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
            /* 滑块 */
            width: 6px;
            border-radius: 5px;
            background: #00e8ff;
        }

        .td {
            display: flex;
            width: 100%;
            padding: 7px;
            color: #5ad7d8;

            .td-item {
                width: 33%;
                text-align: center;
            }
        }
    }
}
</style>