<template>
    <!-- <h1>地图1</h1> -->
    <!-- <div id="centerMap" class="bdMap"></div> -->
    <div class="bdMap">
        <baidu-map @ready="handler" :center="center" :zoom="zoom" :mapStyle="mapStyle" :scroll-wheel-zoom='false'
            class="container">
            <!-- <bm-view class="map" center="成都"></bm-view>
            <bm-transit :start="p1" :end="p3" :auto-viewport="true" location="成都"></bm-transit>
            <bm-label content="四川大学" :position="p2" :labelStyle="{color: 'red',border:'none',fontSize : '24px',background:'transparent'}" title="Hover me"/> -->
            <!-- <bm-bus keyword="256" :auto-viewport="true" location="成都"></bm-bus> -->


            <!-- <bm-polyline :path="polylinePath" stroke-color="red" :stroke-opacity="0.8" :stroke-weight="5"></bm-polyline> -->


            <!-- <bm-label content="丽都公园" :position="p1" :labelStyle="{color: 'red',border:'none',fontSize : '24px',background:'transparent'}" title="Hover me"/>
             <bm-label content="四川大学" :position="p2" :labelStyle="{color: 'red',border:'none',fontSize : '24px',background:'transparent'}" title="Hover me"/>
             <bm-label content="荣华大厦" :position="p3" :labelStyle="{color: 'red',border:'none',fontSize : '24px',background:'transparent'}" title="Hover me"/> -->
        </baidu-map>
    </div>
</template>

<script>
//引入地图样式
import mapJSON from '@/common/map/mapStyle.json';

export default {
    data() {
        return {
            center: { lng: 104.09773255427933, lat: 30.6371345859971 },
            zoom: 12,
            mapStyle: {
                styleJson: [],
            },
            polylinePath: [
                { lng: 104.03865998394318, lat: 30.6358784494035 },
                { lng: 104.09485797664739, lat: 30.6376185155821 },
                { lng: 104.09773255427933, lat: 30.6971345859971 },
            ],
            p1: { lng: 104.03865998394318, lat: 30.6358784494035 },
            p2: { lng: 104.09485797664739, lat: 30.6376185155821 },
            p3: { lng: 104.09773255427933, lat: 30.6971345859971 },
            startStation: '四川大学',
            endStation: '丽都公园',
            myMap: {}
        }
    },
    props: {
        cdata: {
            type: Array,
            default: () => ([])
        }
    },
    mounted() {
        //this.map();
    },
    watch: {
        cdata: {
            handler(newData) {
                this.polylinePath = newData;
                let map = this.myMap;
                map.clearOverlays();
                newData.forEach((item, index, arr) => {
                    let p1 = new BMap.Point(item.lng, item.lat);
                    let nextIndex = 1;
                    if (index < arr.length) {
                        nextIndex = ++index;
                    }
                    console.log(nextIndex);
                    if(nextIndex==arr.length){
                        //console.log("超出数组索引边界了");
                        return;
                    }
                    let p2 = new BMap.Point(arr[nextIndex].lng, arr[nextIndex].lat);
                    let subwayLine = new BMap.TransitRoute(map, {
                        renderOptions: { map: map, autoViewport: false },
                        transitType: BMAP_TRANSIT_TYPE_IN_CITY,
                        lineType: BMAP_LINE_TYPE_SUBWAY,
                        transitPolicy: BMAP_TRANSIT_POLICY_FIRST_SUBWAYS,
                    });
                    subwayLine.search(p1, p2);
                    subwayLine.setPolylinesSetCallback((result) => {
                        // 清除路线规划
                        subwayLine.clearResults();
                        console.log(result);
                        const points = [];
                        result.forEach((item, index, arr) => {
                            if(item.Hr){
                                item.Hr.map(function (item) {
                                    points.push(new BMap.Point(item.lng, item.lat));
                                })   
                            }else{
                                item.Ur.map(function(item){
                                    points.push(new BMap.Point(item.lng, item.lat));
                                })
                            }
                            const polyline = new BMap.Polyline(points, { strokeColor: 'red', strokeWeight: 4, strokeOpacity: 1, 'enableDragging': true }) // 创建折线
                            map.addOverlay(polyline) // 增加折线
                        })
                    })
                });
                // let p1 = new BMap.Point(newData[0].lng, newData[0].lat);
                // let p2=new BMap.Point(newData[newData.length-1].lng,newData[newData.length-1].lat);
                // let p3 = new BMap.Point(newData[1].lng, newData[1].lat);
            }
        }
    },
    methods: {
        map() {
            // 百度地图API功能
            let map = new BMap.Map("centerMap"); // 创建Map实例
            map.centerAndZoom(new BMap.Point(104.07258, 30.550701), 11); // 初始化地图,设置中心点坐标和地图级别
            //添加地图类型控件
            map.addControl(
                new BMap.MapTypeControl({
                    mapTypes: [BMAP_NORMAL_MAP, BMAP_HYBRID_MAP],
                })
            );
            map.setCurrentCity("成都"); // 设置地图显示的城市 此项是必须设置的
            map.enableScrollWheelZoom(true);

            let styleJson = mapJSON;
            map.setMapStyleV2({ styleJson: styleJson });
        },
        handler: function ({ BMap, map }) {
            map.setMapStyleV2({ styleId:'8b0af6eac35788fc7aa2475991fa94b5' });
            map.setCurrentCity("成都");
            this.myMap = map;
            let controlOpts={
                anchor:BMAP_ANCHOR_BOTTOM_RIGHT,   //位置：地图右下角
                type:BMAP_NAVIGATION_CONTROL_ZOOM  //类型：仅包含缩放按钮
            };
            map.addControl(new BMap.NavigationControl(controlOpts));
            //超出成都边界后，重新固定在成都的范围
            var strictBounds = map.getBounds();
            console.log(strictBounds);
            map.addEventListener("dragging", function () {
                if (strictBounds.containsPoint(map.getCenter())) return;
                var c = map.getCenter();
                let x = c.lng;
                let y = c.lat;
                let maxX = strictBounds.getNorthEast().lng;
                let maxY = strictBounds.getNorthEast().lat;
                let minX = strictBounds.getSouthWest().lng;
                let minY = strictBounds.getSouthWest().lat;
                if (x < minX) x = minX;
                if (x > maxX) x = maxX;
                if (y < minY) y = minY;
                if (y > maxY) y = maxY;
                map.centerAndZoom(new BMap.Point(x, y), map.getZoom());
            });
        }
    }
}
</script>

<style lang="less" scoped>
.bdMap {
    width: 98%;
    height: 450px;
    margin-top: 20px;
    overflow: hidden;

    .container {
        height: 100%;

        .map {
            width: 100%;
            height: 100%;
        }

        ::v-deep .anchorBL {
            display: none !important;
        }
    }
}
</style>