import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '../views/Login';
import EntraceWBS from '@/views/EntranceWithBigScreen';
import index from '@/views/index';
import index1 from '@/views/index1';

Vue.use(VueRouter)
const route=new VueRouter({
    routes:[{
        path:'/login',
        name:'login',
        component:Login,
        alias:'/'
    },
    {
        path:'/entraceWBS',
        name:'entraceWBS',
        component:EntraceWBS
    },
    {
        path:'/index',
        name:'index',
        component:index
    },
    {
        path:'/index1',
        name:'index1',
        component:index1
    }
    ]
})

export default route