<template>
    <div class="d-flex jc-center">
        <BottomCenterChart :cdata="stationVirusSituation"></BottomCenterChart>
    </div>
</template>

<script>
import BottomCenterChart from './echarts/bottom/bottomCenterChart';
import { mapState } from 'vuex';

export default{
    data(){
        return{
            
        }
    },
    components:{
        BottomCenterChart
    },
    computed: {
        ...mapState({
            stationVirusSituation: state => state.stationVirusSituation,
        }),
    },
}
</script>

<style>

</style>