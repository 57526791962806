<template>
  <div :id="id" :class="className" :style="{ height: height, width: width }" />
</template>

<script>
import tdTheme from './theme.json' // 引入默认主题
// import '../map/henan.js'

export default {
  name: 'echart',
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    id: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '2.5rem'
    },
    options: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      chart: null
    }
  },
  watch: {
    options: {
      handler(options) {
        // 设置true清空echart缓存
        this.chart.setOption(options, true)
      },
      deep: true
    }
  },
  mounted() {
    this.$echarts.registerTheme('tdTheme', tdTheme); // 覆盖默认主题
    this.initChart();
  },
  beforeDestroy() {
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    initChart() {
      // 初始化echart
      this.chart = this.$echarts.init(this.$el, 'tdTheme')
      this.chart.setOption(this.options, true)
      let that=this;      
      this.chart.on('click', function (params) {
        console.log(params.data);
       if(params.componentSubType=="pie"){
        //在这里面使用this会找不到store
        console.log(that.$store);
        if(params.data.tId && params.data.uId){
          that.$store.dispatch("trainCross",{tId:params.data.tId,uId:params.data.uId})
        }
       }else if(params.componentSubType=="bar"){
        if(params.data.sId && params.data.uId){
          that.$store.dispatch("stationCross",{sId:params.data.sId,uId:params.data.uId})
        }
       }
      })
    }
  }
}
</script>

<style></style>
