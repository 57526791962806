import request from '../utils/request'
import {uploadFile} from '@/utils/request'

//上传Excel文件
export function upload1(data,callback){
  return uploadFile({
   url:'/common/upload1',
   method:'post',
   data:data,
   timeout:1000*60*30,
   callback
  })
}

//感染者进出站时间
export function patientInAndOut(query){
  return request({
    url:'/screen/patientInAndOut',
    method:'get',
    params:query
  })
}

//感染者ID列表
export function patientList(){
  return request({
    url:'/screen/patientList',
    method:'get'
  })
}

//易感染者感染列表
export function probabilityList(data){
  return request({
    url:'/screen/probabilityList',
    method:'post',
    data
  })
}

//感染者经过主要站点坐标
export function positionList(query){
  return request({
    url:'/screen/positionList',
    method:'get',
    params:query
  })
}

//车站风险信息
export function riskOfStationInfo(data){
  return request({
    url:'/screen/riskOfStationInfo',
    method:'post',
    data
  })
}

//病势态势传播
export function stationVirusSituationInfo(query){
  return request({
    url:'/screen/stationVirusSituationInfo',
    method:'get',
    params:query
  })
}

//车次风险接口
export function riskOfTrainInfo(data){
  return request({
    url:'/screen/riskOfTrainInfo',
    method:'post',
    data
  })
}

//感染交叉时间
export function trainCrossList(query){
  return request({
    url:'/screen/trainCrossList',
    method:'get',
    params:query
  })
}

//车站感染交叉时间
export function stationCrossList(query){
  return request({
    url:'/screen/stationCrossList',
    method:'get',
    params:query
  })
}