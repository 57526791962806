<template>
    <div class="bLeftBox">
        <Chart :cdata="cdata" class="chart"></Chart>
    </div>
</template>

<script>
import Chart from './chart.vue';
import { mapState } from 'vuex';

export default{
    data(){
        return{
            //add code
        }
    },
    components:{
        Chart,
    },
    computed: {
        ...mapState({
            cdata: state => state.rotEntity,
        }),
    },
}
</script>

<style lang="less" scoped>
.bLeftBox{
    width: 100%;
    height: 100%;

    .chart{
        top:0;
        left:0;
        position: absolute;
    }
}
</style>