<template>
    <div class="d-flex jc-center">
        <BottomRightChart></BottomRightChart>
    </div>
</template>

<script>
import BottomRightChart from './echarts/bottom/bottomRightChart';
export default{
    data(){
        return{
            
        }
    },
    components:{
        BottomRightChart
    }
}
</script>

<style>

</style>