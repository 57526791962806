import axios from 'axios'
import {Message,MessageBox} from 'element-ui'

const service=axios.create({
	baseURL:process.env.VUE_APP_BASE_API,
	// 超时(6分钟)
	timeout: 1000*60*60
})

service.interceptors.request.use(
	config=>{
		if(sessionStorage.getItem("token")){
			config.headers.Authorization='Bearer '+sessionStorage.getItem("token")
		}
		return config
	},
	error=>{
		return Promise.reject(error)
	}
)

service.interceptors.response.use(
	response=>{
		//console.log(JSON.stringify(response.data).replace("var tvInfoJs=",""))
		console.log(response.data);
		let res=response.data;
		if(res.code ==500){
			Message({
				message:res.msg,
				type:'error',
				duration:5*1000
			})
		    return Promise.reject(new Error('Error'));
		}else if(res.code==401){
			MessageBox.alert('会话已过期，请重新登录！','提示',{
				confirmButtonText:'重新登录',
				type:'error'
			}).then(()=>{
				sessionStorage.removeItem('token');
				location.reload();
			})
		}else{
			return res
		}
	},
	error=>{
		console.log('err:'+error)
		Message({
			message:error.message,
			type:'error',
			duration:5*1000
		})
		return Promise.reject(error)
	}
)

//上传大文件
export function uploadFile({url,data,timeout,callback}){
  timeout ? service.defaults.timeout=timeout : '';
  return service.post(url,data,{
    headers:{'Content-Type':'multipart/form-data'},
    onUploadProgress:progressEvent=>{
      callback && callback(progressEvent)
    }
  }).then(res=>{
    return res
  }).catch((r)=>{
    console.error(r);
  })
}

export default service