<template>
    <div id="entranceWBS" ref="appRef" v-loading="loading"
    element-loading-text="拼命解析中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)">
        <!-- <el-button type="text" icon="el-icon-back" @click="handleBack">返回</el-button><br/>
        <h2>大屏入口</h2> -->
        <div class="bg">
            <div class="top">
                <h1 align="center">突发传染病事件信息平台</h1>
                <el-button style="position: absolute;left: 0;top:0;font-size: 24px;" type="text" icon="el-icon-back"
                    @click.native="handleBack" v-show="false">返回</el-button>
            </div>
            <div class="body-box">
                <div class="button-img-box">
                    <div>
                        <!-- 导入数据 -->
                        <el-upload class="uploadFile" ref="uploadFile" action :limit="1" :auto-upload="false" :on-change="fileChange"
                            :file-list="fileList" :show-file-list="false">
                            <div class="import">
                                <img src="@/assets/img/import.png" />
                            </div>
                        </el-upload>
                    </div>
                    <div>
                        <!-- 进入大屏 -->
                        <div class="big-screen">
                            <img src="@/assets/img/big_screen.png" @click="enterBigScreen" />
                        </div>
                    </div>
                </div>
            </div>
            <el-dialog id="processDlg" title="上传进度" :visible.sync="fileFlag" :show-close="false"
                :close-on-press-escape="false" :close-on-click-modal="false" center width="30%">
                <div class="processLayout">
                    <el-progress type="circle" :percentage="filePercent"></el-progress>
                </div>
                <!-- <div slot="footer" class="dialog-footer">
					<el-button type="primary" @click="dlgProcessVisible=false">确定</el-button>
				</div> -->
            </el-dialog>
        </div>
    </div>
</template>

<script>
import drawMixin from '@/mixins/drawMixin.js';
import SparkMD5 from 'spark-md5';
import { upload1} from '@/api/index';

export default {
    data() {
        return {
            fileList: [],
            fileFlag: false,
            filePercent: 0,
            loading:false,
        }
    },
    mixins: [drawMixin],
    mounted() {
        //监听键盘Esc按键事件
        document.addEventListener('keydown', this.keyBack)
    },
    methods: {
        handleBack() {
            this.$router.replace({ path: '/login' });
            document.removeEventListener('keydown', this.keyBack);
        },
        keyBack: function (e) {
            console.log(e);
            let self = this;
            let key = e.key || e.keyCode;
            if (key == 'Escape' || key == 27) {
                self.handleBack();
            }
        },
        fileChange(file) {
            if (file.status === "ready") {
                this.fileList = [];
                // console.log(file.raw);
                // let fileReader = new window.FileReader();
                // let Spark = new SparkMD5.ArrayBuffer();
                // fileReader.readAsArrayBuffer(file.raw);
                // let md5 = '';
                // fileReader.onload = function (e) {
                //     console.log(e.target.result);
                //     Spark.append(e.target.result);
                //     md5 = Spark.end();
                //     console.log(md5);
                // }
                this.fileList.push(file.raw);
                //FormDate对象
                let formData = new FormData();
                //文件对象
                formData.append('file', file.raw);
                let that = this;
                upload1(formData, (progressEvent) => {
                    this.fileFlag = true;
                    this.filePercent = parseFloat((progressEvent.loaded / progressEvent.total) * 100).toFixed(1)
                    if (this.filePercent >= 100) {
                        this.fileFlag = false;
                        this.filePercent = 0;
                        this.loading=true;
                    }
                }).then(resp => {
                    if (resp.code == 200) {
                        this.$message.success("解析文件成功");
                    } else {
                        this.$message.error(resp.msg);
                    }
                    this.$refs.uploadFile.clearFiles;
                    this.fileList=[];
                    this.loading=false;
                }).catch(err => {
                    this.$refs.uploadFile.clearFiles;
                    this.fileList=[];
                    this.loading=false;
                    console.log(err);
                });
            }
        },
        enterBigScreen() {
            console.log("进入大屏");
            this.$store.commit("setCLoadingFlag",false);
            this.$router.push({ name: 'index' });
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/entranceWBS.scss';

.processLayout {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
</style>