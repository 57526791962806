<template>
    <!-- <h1>地图2</h1> -->
    <div class="bdMap">
        <baidu-map id="bottomCenter" @ready="handler" :center="center" :zoom="zoom" :mapStyle="mapStyle" :scroll-wheel-zoom='false'
            class="container">

            
            <!-- <bm-circle :center="circlePath1.center" :radius="circlePath1.radius" stroke-color="blue" :fillColor="colorTest"
                :stroke-opacity="0.5" :stroke-weight="2" @click="setFillColor"></bm-circle> -->


            <!-- <bm-circle :center="circlePath2.center" :radius="circlePath2.radius" stroke-color="yellow" fillColor="yellow"
                :stroke-opacity="0.5" :stroke-weight="2"></bm-circle>
            <bm-circle :center="circlePath3.center" :radius="circlePath3.radius" stroke-color="red" fillColor="red"
                :stroke-opacity="0.5" :stroke-weight="2"></bm-circle> -->

            
        </baidu-map>
    </div>
    <!-- <div id="bottomMap" class="bdMap">

    </div> -->
</template>

<script>
//局部注册   百度地图
// import BaiduMap from 'vue-baidu-map/components/map/Map.vue';
//引入地图样式
import mapJSON from '@/common/map/mapStyle.json';


export default {
    data() {
        return {
            center: { lng: 104.09773255427933, lat: 30.6971345859971 },
            zoom: 12,
            mapStyle: {
                styleJson: []
            },
            circlePath1: {
                center: {
                    lng: 104.09485797664739,
                    lat: 30.6376185155821
                },
                radius: 1500
            },
            circlePath2: {
                center: {
                    lng: 104.09773255427933,
                    lat: 30.6971345859971
                },
                radius: 1200
            },
            circlePath3: {
                center: {
                    lng: 104.03865998394318,
                    lat: 30.6358784494035
                },
                radius: 700
            },
            colorTest:'blue',
            myMap: {}
        }
    },
    props: {
        cdata: {
            type: Array,
            default: () => ([])
        }
    },
    mounted() {
        //this.map();
    },
    watch: {
        cdata: {
            handler(newData) {
                let map = this.myMap;
                console.log(newData);
                if(newData[0].status){
                    map.clearOverlays();
                    return;
                }
                // let startPosition = newData[0].position.split(",");
                // let endPosition = newData[newData.length - 1].position.split(",");
                // let subwayLine = new BMap.TransitRoute(map, {
                //     renderOptions: { map: map, autoViewport: true },
                //     transitType: BMAP_TRANSIT_TYPE_IN_CITY,
                //     lineType: BMAP_LINE_TYPE_SUBWAY,
                //     transitPolicy: BMAP_TRANSIT_POLICY_FIRST_SUBWAYS,
                // });
                // startPosition = new BMap.Point(startPosition[0], startPosition[1]);
                // endPosition = new BMap.Point(endPosition[0], endPosition[1]);
                // subwayLine.search(startPosition, endPosition);
                // subwayLine.setPolylinesSetCallback((result) => {
                //     // 清除路线规划
                //     subwayLine.clearResults();
                //     console.log(result);
                // });
                map.clearOverlays();
                newData.map((item,index) => {
                    let position = item.position.split(",");
                    let radius_index = function (num) {
                        if (num < 1) {
                            return 800;
                        } else if (num >= 1 && num < 10) {
                            return 1300;
                        } else {
                            return 1600;
                        }
                    }
                    let color_index = function (num) {
                        if (num < 1) {
                            return '#69ecfe';
                        } else if (num >= 1 && num < 10) {
                            return '#fbed43';
                        } else{
                            return '#f95d49';
                        }
                    };
                    const color = color_index(item.num2);
                    const radius=radius_index(item.num2);
                    let circleConfig = { strokeColor: color, strokeWeight: 2, fillColor: color }
                    const point = new BMap.Point(position[0], position[1])
                    const circle = new BMap.Circle(point, radius, circleConfig);
                    map.addOverlay(circle);
                    //单击圆显示态势量
                    circle.addEventListener("click",function(e){
                        position=`${circle.getCenter().lng},${circle.getCenter().lat}`;
                        let num=0;
                        newData.forEach(item=>{
                            if(item.position==position){
                                num=item.num;
                            }
                        });
                        let opts={
                            width:250,
                            height:100,
                            title:"态势量"
                        }
                        num=num+'';
                        let infoWindow=new BMap.InfoWindow(num,opts);
                        map.openInfoWindow(infoWindow,circle.getCenter());
                    })
                })
            }
        }
    },
    methods: {
        map() {
            // 百度地图API功能
            let map = new BMap.Map("bottomMap"); // 创建Map实例
            map.centerAndZoom(new BMap.Point(104.07258, 30.550701), 12); // 初始化地图,设置中心点坐标和地图级别
            //添加地图类型控件
            map.addControl(
                new BMap.MapTypeControl({
                    mapTypes: [BMAP_NORMAL_MAP, BMAP_HYBRID_MAP],
                })
            );
            map.setCurrentCity("成都"); // 设置地图显示的城市 此项是必须设置的
            map.enableScrollWheelZoom(true);

            let styleJson = mapJSON;
            map.setMapStyleV2({ styleJson: styleJson });

            //创建标记点
            //四川大学
            let marker1 = new window.BMap.Marker(new BMap.Point(104.09485797664739, 30.6376185155821));
            //荣华大厦
            let marker2 = new window.BMap.Marker(new BMap.Point(104.09773255427933, 30.6971345859971));
            //丽都公园
            let marker3 = new window.BMap.Marker(new BMap.Point(104.03865998394318, 30.6358784494035));
            //在地图上添加标记点
            map.addOverlay(marker1);
            map.addOverlay(marker2);
            map.addOverlay(marker3);
        },
        handler: function ({ BMap, map }) {
            map.setMapStyleV2({ styleJson: mapJSON });
            map.setCurrentCity("成都");
            this.myMap = map;
            
            // 添加绽放控件
            let controlOpts={
                anchor:BMAP_ANCHOR_BOTTOM_RIGHT,
                type:BMAP_NAVIGATION_CONTROL_ZOOM
            };
            map.addControl(new BMap.NavigationControl(controlOpts));

            //超出成都边界后，重新固定在成都的范围
            var strictBounds = map.getBounds();
            console.log(strictBounds);
            map.addEventListener("dragging", function () {
                if (strictBounds.containsPoint(map.getCenter())) return;
                var c = map.getCenter();
                let x = c.lng;
                let y = c.lat;
                let maxX = strictBounds.getNorthEast().lng;
                let maxY = strictBounds.getNorthEast().lat;
                let minX = strictBounds.getSouthWest().lng;
                let minY = strictBounds.getSouthWest().lat;
                if (x < minX) x = minX;
                if (x > maxX) x = maxX;
                if (y < minY) y = minY;
                if (y > maxY) y = maxY;
                map.centerAndZoom(new BMap.Point(x, y), map.getZoom());
            })
        },

        setFillColor:function(){
            this.colorTest='#6f6cd8'
        }
    }
}
</script>

<style lang="less" scoped>
.bdMap {
    width: 100%;
    height: 350px;
    margin-top: 20px;
    overflow: hidden;

    .container {
        height: 100%;
    }

    ::v-deep .anchorBL {
        display: none !important;
    }
}
</style>