<template>
  <div id="app">
    <router-view></router-view>
    <div class="maskBg" v-show="status">
      <div class="zz">
        <div class="list">
          <div class="tr">
            <div class="tr-item">User ID</div>
            <div class="tr-item">交叉时间(s)</div>
            <!-- <div class="tr-item">风险信息</div> -->
          </div>
          <div class="table-body">
            <div class="td" v-for="item in trainCrossList" :key="item.id">
              <div class="td-item">{{ item.id }}</div>
              <div class="td-item">{{ item.num }}</div>
              <!-- <div class="td-item" v-show="false">
                <span v-if="item.num > 0 && item.num<100" style="color: #5ad7d8">低风险</span>
                <span v-else-if="item.num >= 100 && item.num < 300" style="color: #fbed43">中风险</span>
                <span v-else-if="item.num >= 300 && item.num < 500" style="color: #f95d49">高风险</span>
                <span v-else-if="item.num>=500 && item.num<1000" style="color: #0a0a0a">重大风险</span>
                <span v-else style="color: #fefefe;">非正常数据</span>
              </div> -->
            </div>
          </div>
        </div>
        <button @click="confirm">确定</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'App',
  data() {
    return {
      //add code
    }
  },
  computed: {
    ...mapState({
      status: state => state.trainCrossFlag,
      trainCrossList:state=>state.trainCrossList
    })
  },
  methods: {
    confirm() {
      this.$store.commit('setTrainCrossFlag', false);
    }
  }
}
</script>

<style lang="less" scoped>
#app {
  width: 100%;
  height: 100vh;
  background-color: #0D1E3C;
  overflow: hidden;
}

.maskBg {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);

  .zz {
    width: 30vw;
    height: 400px;
    background: #0d1e3c;
    margin: 10vw auto;
    font-size: 45px;
    color: #fff;
    font-family: '微软雅黑';
    text-align: center;
    position: relative;

    button {
      width: 100px;
      height: 30px;
      background-color: #39e4cd;
      color: #fefefe;
      font-size: 18px;
      line-height: 18px;
      cursor: pointer;
      margin-top: 0;
    }

    .list {
      width: 95%;
      height: 80%;
      font-size: 12px;
      position: relative;
      margin: 10px auto;

      .tr {
        display: flex;
        width: 100%;
        color: #fff;
        background: #31728a;
        border-radius: 10px;
        padding: 10px;

        .tr-item {
          width: 50%;
          text-align: center;
        }
      }

      .table-body {
        margin: 0 5px 0 10px;
        height: 80%;
        overflow-y: auto;
        background: linear-gradient(to bottom, #295d77, transparent);

        &::-webkit-scrollbar {
          width: 6px;
          height: 100%;
        }

        &::-webkit-scrollbar-track {
          /* 外层轨道 */
          border-radius: 10px;
        }

        &::-webkit-scrollbar-track-piece {
          /*内层轨道，滚动条中间部分 */
          border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
          /* 滑块 */
          width: 6px;
          border-radius: 5px;
          background: #00e8ff;
        }

        .td {
          display: flex;
          width: 100%;
          padding: 7px;
          color: #5ad7d8;

          .td-item {
            width: 50%;
            text-align: center;
          }
        }
      }
    }
  }
}</style>
