/**
 * @param {Function} fn 防抖函数
 * @param {Number} delay 延迟时间
 */
export function debounce(fn,delay){
	var timer;
	return function(){
		var context=this;
		var args=arguments;
		clearTimeout(timer);
		timer=setTimeout(function(){
			fn.apply(context,args);
		},delay);
	};
}
/**
 * @param {date} time 需要转换的时间
 * @param {String} fmt  需要转换的格式 如yyyy-MM-dd,yyyy-MM-dd HH:mm:ss
 */
export function formatTime(time,fmt){
	if(!time){
		return '';
	}else{
		const date=new Date(time);
		const o={
			'M+':date.getMonth()+1,
			'd+':date.getDate(),
			'H+':date.getHours(),
			'm+':date.getMinutes(),
			's+':date.getSeconds(),
			'q+':Math.floor((date.getMonth()+3)/3),
			S:date.getMilliseconds(),
		};
		if(/(y+)/.test(fmt)){
			fmt=fmt.replace(
				RegExp.$1,
				(date.getFullYear()+'').substr(4-RegExp.$1.length)
			);
		}
		for(const k in o){
			if(new RegExp('('+k+')').test(fmt))
			{
				fmt=fmt.replace(
				  RegExp.$1,
				  RegExp.$1.length===1 ? o[k] : ('00'+o[k]).substr((''+o[k]).length)
				);
			}
		}
		return fmt;
	}
}

/**
 * 排序(升序)
 */
export function compare(key){
    return function(value1,value2){
        var val1=value1[key];
        var val2=value2[key];
        return (val1 < val2) ? -1 : (val1 > val2) ? 1 : 0;//可以按照字母排序
    }
}

/**
 * 排序(降序)
 */
export function compareDesc(key){
	return function(value1,value2){
        var val1=value1[key];
        var val2=value2[key];
        return (val1 > val2) ? -1 : (val1 < val2) ? 1 : 0;//可以按照字母排序
    }
}