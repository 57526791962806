<template>
    <div class="content">

    <!-- 进站时间-->
        <div class="box1" v-show="false">
            <div class="box1-left">
                <div class="left-item">进站点</div>
                <div class="left-item">进站时间</div>
                <div class="left-item">出站点</div>
                <div class="left-item">出站时间</div>
            </div>
            <div class="box1-right">
                <div class="right-item">红石公园</div>
                <div class="right-item">2023-03-21 12:32</div>
                <div class="right-item">崔家店</div>
                <div class="right-item">2023-03-21 13:32</div>
            </div>
        </div>

        <!--易感人群-->
        <div class="box2" v-show="false">
           <div class="tr">
               <div class="tr-item">User ID</div>
               <div class="tr-item">感染概率</div>
               <div class="tr-item">风险信息</div>
           </div>

            <div class="table-body">
                <div class="td" v-for="item in 50" :key="item">
                    <div class="td-item">610000154{{item < 10 ? '0' + item : item}}</div>
                    <div class="td-item">0.451{{50-item}}</div>
                    <div class="td-item">
                        <span v-if="item < 3" style="color: #f95d49">重度易感</span>
                        <span v-else-if="item < 9" style="color: #fbed43">中度易感</span>
                        <span v-else style="color: #5ad7d8">轻度易感</span>
                    </div>
                </div>
            </div>

        </div>

        <!-- 测试地图缩放问题 -->
        <div class="box3">
            <div id="centerMap" class="bdMap"></div>
        </div>

    </div>
</template>

<script>
import mapJSON from '@/common/map/mapStyle.json';

export default {
    data() {
        return {
            fileList: [],
        }
    },
    mounted() {
        this.map();
    },
    methods: {
        map() {
            // 百度地图API功能
            let map = new BMap.Map("centerMap"); // 创建Map实例
            map.centerAndZoom(new BMap.Point(104.07258, 30.550701), 11); // 初始化地图,设置中心点坐标和地图级别
            //添加地图类型控件
            map.addControl(
                new BMap.MapTypeControl({
                    mapTypes: [BMAP_NORMAL_MAP, BMAP_HYBRID_MAP],
                })
            );
            map.setCurrentCity("成都"); // 设置地图显示的城市 此项是必须设置的
            map.enableScrollWheelZoom(true);

            let styleJson = mapJSON;
            map.setMapStyleV2({ styleJson: styleJson });
        }
    }
}
</script>

<style lang="scss" scoped>
.content{
    width: 100%;
    height: 100vh;
    overflow: auto;
}

.box1{
    display: flex;
    padding: 20px;
    white-space: nowrap;

    .box1-left{


        .left-item{
            background: #32718a;
            color: #fff;
            text-align: center;
            width: 150px;
            height: 40px;
            line-height: 40px;
            border-bottom: 1px solid #0d213f;

            &:first-child{
                border-radius: 10px 10px 0 0;
                position: relative;

                &::before{
                    content: '1';
                    display: inline-block;
                    position: absolute;
                    right: 10px;
                    top: -3px;
                    background: #fff849;
                    height: 15px;
                    width: 15px;
                    color: #333;
                    font-size: 12px;
                    line-height: 14px;
                }
            }

            &:last-child{
                border: none;
                border-radius: 0 0 10px 10px;
            }
        }
    }

    .box1-right{
        background: linear-gradient(to right, #295d77, transparent);
        width: 200px;
        margin: 8px 0;
        color: #fff;

        .right-item{
            line-height: 30px;
            padding-left: 10px;
            margin-bottom: 5px;

            &:last-child{
                margin-bottom: 0;
            }
        }
    }
}

.box2{
    width: 400px;
    padding: 20px;

    .tr{
        display: flex;
        width: 100%;
        color: #fff;
        background: #31728a;
        border-radius: 10px;
        padding: 10px;

        .tr-item{
            width: 33%;
            text-align: center;
        }
    }

    .table-body{
        margin: 0 5px 0 10px;
        height: 450px;
        overflow-y: auto;
        background: linear-gradient(to bottom, #295d77, transparent);

        &::-webkit-scrollbar{
            width: 6px;
            height: 100%;
        }

        &::-webkit-scrollbar-track {  /* 外层轨道 */
            border-radius: 10px;
        }

        &::-webkit-scrollbar-track-piece {  /*内层轨道，滚动条中间部分 */
            border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {  /* 滑块 */
            width:6px;
            border-radius: 5px;
            background: #00e8ff;
        }

        .td{
            display: flex;
            width: 100%;
            padding: 7px;
            color: #5ad7d8;

            .td-item{
                width: 33%;
                text-align: center;
            }
        }
    }
}

.box3{
    width: 569px;
    height: 400px;
    float: right;
    position: relative;

    .bdMap {
    width: 98%;
    height: 450px;
    margin-top: 20px;
    overflow: hidden;
    }
}
</style>