import Vue from 'vue'
import Vuex from 'vuex'
import { login } from '@/api/login'
import {trainCrossList,stationCrossList} from '@/api/index'
import {compare,compareDesc} from '@/utils/index.js'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    cLoadingFlag: false,
    userList: [],
    infoInAndOut: {},
    probAbilityList: [],
    mainPositionList: [],
    rosEntity: {},
    rotEntity: {},
    stationVirusSituation: [],
    trainCrossList:[],
    trainCrossFlag:false,
  },
  getters: {
    cLoadingFlag: state => state.cLoadingFlag,
    userList: state => state.userList,
    infoInAndOut: state => state.infoInAndOut,
    probAbilityList: state => state.probAbilityList,
    mainPositionList: state => state.mainPositionList,
    rosEntity: state => state.rosEntity,
    rotEntity: state => state.rotEntity,
    stationVirusSituation: state => state.stationVirusSituation,
    trainCrossList:state=>state.trainCrossList,
    trainCrossFlag:state=>state.trainCrossFlag
  },
  mutations: {
    setCLoadingFlag: (state, flag) => {
      state.cLoadingFlag = flag;
    },
    setUserList: (state, userList) => {
      state.userList = userList;
    },
    setInfoInAndOut: (state, obj) => {
      state.infoInAndOut = Object.assign(obj);
    },
    setProbAbilityList: (state, probAbilityList) => {
      state.probAbilityList = probAbilityList;
    },
    setMainPositionList: (state, mainPositionList) => {
      state.mainPositionList = mainPositionList;
      console.log(mainPositionList);
    },
    setRosEntity: (state, rosEntity) => {
      state.rosEntity = Object.assign(rosEntity);
    },
    setRotEntity: (state, rotEntity) => {
      state.rotEntity = Object.assign(rotEntity);
    },
    setStationVirusSituation: (state, stationVirusSituation) => {
      state.stationVirusSituation = stationVirusSituation;
    },
    setTrainCrossList:(state,list)=>{
      state.trainCrossList=list;
      console.log(state.trainCrossList);
    },
    setTrainCrossFlag:(state,flag)=>{
      state.trainCrossFlag=flag;
      console.log(state.trainCrossFlag);
    }
  },
  actions: {
    // 登录
    Login({ commit }, userInfo) {
      const username = userInfo.username.trim()
      const password = userInfo.password
      const code = userInfo.code
      const uuid = userInfo.uuid
      return new Promise((resolve, reject) => {
        login(username, password, code, uuid).then(res => {
          sessionStorage.setItem("token", res.token)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    //感染交叉时间
    trainCross({commit},{tId,uId}){
      console.log(uId);
      const listQuery={trainId:tId,userId:uId};
      trainCrossList(listQuery).then(resp=>{
        if(resp.code==200){
          let trainCrossList=[];
          console.log(resp.data);
          for(let item in resp.data){
            trainCrossList.push({id:item,num:resp.data[item]});
          }
          trainCrossList=trainCrossList.sort(compareDesc("num"));
          commit('setTrainCrossList',trainCrossList);
          commit('setTrainCrossFlag',true);
        }else{
          console.log(resp.msg);
          commit('setTrainCrossFlag',false);
        }
      }).catch(err=>{
        console.error(err);
        commit('setTrainCrossFlag',false);
      })
    },
    //车站感染交叉时间
    stationCross({commit},{sId,uId}){
      const listQuery={stationId:sId,userId:uId};
      stationCrossList(listQuery).then(resp=>{
        if(resp.code==200){
          let stationCrossList=[];
          for(let item in resp.data){
            stationCrossList.push({id:item,num:resp.data[item]});
          }
          let crossList=stationCrossList.sort(compareDesc("num"));
          commit('setTrainCrossList',crossList);
          commit('setTrainCrossFlag',true);
        }else{
          console.log(resp.msg);
          commit('setTrainCrossFlag',false);
        }
      }).catch(err=>{
        consolek.log(err);
      })
      commit('setTrainCrossFlag',true);
    }
  }
})
export default store