<template>
    <div class="box1">
        <div class="box1-left">
            <div class="left-item">进站点</div>
            <div class="left-item">进站时间</div>
            <div class="left-item">出站点</div>
            <div class="left-item">出站时间</div>
        </div>
        <div class="box1-right">
            <div class="right-item">{{ infoInAndOut.inStation }}</div>
            <div class="right-item">{{ infoInAndOut.inTime }}</div>
            <div class="right-item">{{ infoInAndOut.outStation }}</div>
            <div class="right-item">{{ infoInAndOut.outTime }}</div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default{
    data(){
        return{
            //add code
        }
    },
    mounted(){
        //add code
    },
    computed:{
        ...mapState({
            infoInAndOut: state => state.infoInAndOut,
        }),
    }
}
</script>

<style lang="less" scoped>
.box1{
    display: flex;
    padding: 20px;
    white-space: nowrap;

    .box1-left{


        .left-item{
            background: #32718a;
            color: #fff;
            text-align: center;
            width: 150px;
            height: 40px;
            line-height: 40px;
            border-bottom: 1px solid #0d213f;

            &:first-child{
                border-radius: 10px 10px 0 0;
                position: relative;

                &::before{
                    content: '';
                    display: inline-block;
                    position: absolute;
                    right: 10px;
                    top: -3px;
                    background: #fff849;
                    color: #333;
                    font-size: 12px;
                    line-height: 14px;
                }
            }

            &:last-child{
                border: none;
                border-radius: 0 0 10px 10px;
            }
        }
    }

    .box1-right{
        background: linear-gradient(to right, #295d77, transparent);
        width: 200px;
        margin: 8px 0;
        color: #fff;

        .right-item{
            line-height: 30px;
            padding-left: 10px;
            margin-bottom: 5px;

            &:last-child{
                margin-bottom: 0;
            }
        }
    }
}
</style>