<template>
    <!-- <h1 style="color: #fff;" >左上</h1> -->
    <div class="main">
        <el-form>
            <el-form-item label="User ID" :label-width="formLableWidth">
                <el-select v-model="userId" clearable :popper-append-to-body="false">
                    <el-option v-for="item in userList" :key="item.key" :value="item.value" :label="item.label">
                    </el-option>
                </el-select>
            </el-form-item>
            <div class="group">
                <el-form-item label="感染者病毒产生率" :label-width="formLableWidth">
                    <el-input v-model="baseInfo.rate"></el-input>
                </el-form-item>
                <el-form-item label="感染者防护系数" :label-width="formLableWidth">
                    <el-input v-model="baseInfo.coeff"></el-input>
                </el-form-item>
            </div>
            <div class="group">
                <el-form-item label="乘客呼吸率" :label-width="formLableWidth">
                    <el-input v-model="baseInfo.baseNum"></el-input>
                </el-form-item>
                <el-form-item label="场所平均通风量" :label-width="formLableWidth">
                    <el-input v-model="baseInfo.ventilateNum"></el-input>
                </el-form-item>
            </div>
            <div class="btn">
                <el-button type="primary" @click="handleQuery">确定</el-button>
            </div>
        </el-form>

        
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { patientInAndOut,probabilityList,
         positionList,riskOfStationInfo,
         stationVirusSituationInfo,riskOfTrainInfo} from '@/api/index.js'

export default {
    data() {
        return {
            baseInfo: {
                userId: '',
                rate: '105',
                coeff: '0.3',
                baseNum: '3',
                ventilateNum: 7200,
            },
            userId:'',
			listQuery:{
				id:''
			},
            formLableWidth: '110px'
        }
    },
    mounted(){
        this.userId=this.userList[0].value;
        this.baseInfo.userId=this.userList[0].value;
        this.handleQuery();
    },
    computed: {
        ...mapState({
            userList: state => state.userList,
        }),
    },
    watch:{
		userId:{
			handler(newData){
				let that=this;
				if(newData){
					that.getInAndOut(newData);
                    this.baseInfo.userId=newData;
				}
			}
		},
	},
    methods:{
        getInAndOut(id){
            this.listQuery.id=id;
			patientInAndOut(this.listQuery).then(res=>{
				if(res.code==200){
					let infoInOut=res.data;
					this.$store.commit('setInfoInAndOut',infoInOut);
					this.$store.commit('setCLoadingFlag',true);
				}else{
					this.$store.commit('setCLoadingFlag',false);
					console.log('error');
				}
			}).catch(err=>{
				this.$store.commit('setCLoadingFlag',false);
				console.log(err)
			})
        },
        handleQuery:async function(){
            this.$store.commit('setTrainCrossFlag',false);

            this.listQuery.id=this.baseInfo.userId;
            await positionList(this.listQuery).then(resp=>{
                if(resp.code==200){
                    let pList=resp.data.map(item=>{
                        let tmpStrArr=item.split(",");
                        return{
                            lng:tmpStrArr[0],
                            lat:tmpStrArr[1]
                        }
                    });
                    this.$store.commit('setMainPositionList',pList);
                }else{
					console.error(resp.msg);
                }
            }).catch(err=>{
                console.log(err);
            });
            let data={
                baseInfectNum:this.baseInfo.baseNum,//感染数
                coefficient:this.baseInfo.coeff,//系数
                id:this.baseInfo.userId,
                productionRate:this.baseInfo.rate,//概率
                ventilationQuantity:this.baseInfo.ventilateNum//通过量
            };
            let that=this;
            //感染概率列表
            probabilityList(data).then(resp=>{
                if(resp.code==200){
                    let probAbilityList=resp.data;
                    this.$store.commit('setProbAbilityList',probAbilityList);
                }else{
                    console.error(resp.msg);
                }
            }).catch(err=>{
                console.error(err);
            });

            //车站风险
            let numBackArr=[];
            await riskOfStationInfo(data).then(resp=>{
                if(resp.code==200){
                    let rosInfo=resp.data;
                    let ros_xAxis=[];
                    let ros_series=[];
                    if(resp.msg=="无此车站风险信息！" || resp.msg=="无此列车风险信息！"){
                        //this.$message.success(resp.msg)
                        ros_xAxis.push(''),
                        ros_series.push(0)
                    }else{
                        rosInfo.forEach((item,index)=>{
                          let stationId=index;
                          ros_xAxis.push(item.stationName),
                          ros_series.push({value:item.riskCount,sId:item.stationId,uId:this.userId})
                        });
                    }
                    let rosEntity={xAxis:ros_xAxis,seriesVal:ros_series};
                    numBackArr=ros_series;
                    this.$store.commit('setRosEntity',rosEntity);

                }else{
                    console.error(resp.msg);
                }
            }).catch(err=>{
                console.error(err);
            });

            //病势态势传播
            stationVirusSituationInfo(this.listQuery).then(resp=>{
                if(resp.code==200){
                    let sList=resp.data;
                    if(resp.msg=="此车站暂无交叉易感染人群信息！"){
                        sList=[{status:1}];
                    }else{
                        sList=sList.map((item,index)=>{
                        let rsValue=numBackArr[index].value;
                        return{
                            ...item,
                            num2:rsValue
                        }
                    });
                    }
                    this.$store.commit('setStationVirusSituation',sList);
                }else{
                    console.error(resp.msg);
                }
            }).catch(err=>{
                console.error(err);
            });

            //车次风险
            riskOfTrainInfo(data).then(resp=>{
                if(resp.code==200){
                    let rotInfo=resp.data;
                    let rot_secondData=[];
                    let rot_thirdData=[];
                    if(resp.msg=="无此列车风险信息！"){
                        //this.$message.success(resp.msg)
                        rot_secondData.push({value:0,name:''}),
                        rot_thirdData.push({value:0,name:''})
                    }else{
                        rotInfo.forEach(item=>{
                          rot_secondData.push({value:item.risk,name:item.line+"号线",tId:item.trainId,uId:data.id}),
                          rot_thirdData.push({value:item.risk,name:item.trainId,tId:item.trainId,uId:data.id})
                        });
                    }
                    let rotEntity={CircleSecond:rot_secondData,CircleThird:rot_thirdData};
                    this.$store.commit('setRotEntity',rotEntity);
                }else{
                    console.error(resp.msg);
                }
            }).catch(err=>{
                console.error(err);
            })
        }
    }
}
</script>

<style lang="less" scoped>
.main {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .group {
        display: grid;
        height: 35px;
        grid-template-columns: 50% 50%;
    }

    .btn{
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: center;
        margin-top: 5px;

        ::v-deep .el-button--primary{
            background-color: rgb(27, 64, 92);
            grid-row: 1;
            grid-column:1/ span 2;
            width: 100px;
        }
    }

    ::v-deep .el-form {
        width: 100%;
        height: 70%;
        padding-left: 10px;

        .el-form-item{
            margin-bottom: 10px;
        }
    }

    ::v-deep .el-input {
        width: 90%;
        height: 20px;

        .el-input__inner {
            background-color: rgb(27, 64, 92);
            color: rgba(105, 237, 255, 0.996078431372549);
            height: 20px;
        }
    }

    ::v-deep .el-form-item__label {
        text-align: left;
        font-size: 12px;
        font-weight: bold;
        color: rgba(105, 237, 255, 0.996078431372549);
    }

    ::v-deep .el-select {
        width: 108%;

        .el-input__inner {
            background-image: linear-gradient(to right, rgb(60, 130, 154), rgb(15, 32, 61));
            color: rgba(105, 237, 255, 0.996078431372549);
            border-right: 8px solid rgba(105, 237, 255, 0.996078431372549);
            height: 40px;
        }

        .el-input__inner:focus {
            border-right: 8px solid rgba(105, 237, 255, 0.996078431372549);
            height: 40px;
        }

        .el-input__suffix {
            background-color: rgba(105, 237, 255, 0.996078431372549);
            height: auto;
        }

        .el-select__caret {
            color: #fff;
        }
    }

    ::v-deep .el-select-dropdown {
        background-color: #0D1E3C !important;
        min-width: 256px !important;

        &.el-popper {
            margin-top: 24px;
        }

        .el-select-dropdown__item.selected {
            color: rgba(105, 237, 255, 0.996078431372549);
            font-weight: 700;
        }

        .el-select-dropdown__item {
            color: #fefef3;
        }

        .el-select-dropdown__item.hover,
        .el-select-dropdown__item:hover {
            background-color: #000;
        }
    }
}
</style>