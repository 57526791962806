<template>
	<div id="login">
		<div class="bg">
			<div class="top">
				<h1 align="center">突发传染病事件信息平台</h1>
			</div>

			<div class="loginBox">
				<h1 class="loginTitle">突发传染病事件信息平台登录</h1>
				<div class="login-wrap">
					<el-form :model="loginForm" :rules="rules" ref="ruleForm">
						<el-form-item label="账户:" prop="username">
							<el-input prefix-icon="el-icon-user" v-model="loginForm.username" auto-complete="off"
								placeholder="用户名"></el-input>
						</el-form-item>
						<el-form-item label="密码:" prop="password">
							<el-input prefix-icon="el-icon-lock" type="password" placeholder="密码" auto-complete="off"
								v-model="loginForm.password" @keyup.enter.native="submitForm('ruleForm')"></el-input>
						</el-form-item>
						<div class="login-btn">
							<!-- <slide-verify class="verify" :l="42" :r="10" :w="310" :h="150" sliderText="向右滑动"></slide-verify> -->
							<Verify :barSize="{ width: '300px', height: '40px' }" @success="verifySuccess"
								@error="verifyError" :type="3" :showButton="false"></Verify>
							<el-button type="primary" @click="submitForm('ruleForm')"
								style="margin-left: 20px;">登录</el-button>
						</div>
					</el-form>
				</div>
			</div>
		</div>
		<!-- <div class="login-title">
			<div id="lh">
				<p>登录</p>
			</div>
		</div> -->
	</div>
</template>

<script>
import Vue from 'vue';
import Verify from 'vue2-verify';
// import SlideVerify from 'vue-monoplasty-slide-verify';
// Vue.use(SlideVerify);
import drawMixin from '@/mixins/drawMixin.js';
import { login} from '@/api/login';

Vue.prototype.getFontSize = (size, defalteWidth = 1920) => {
	let clientWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
	if (!clientWidth) return size;
	let scale = (clientWidth / defalteWidth);
	return Number((size * scale).toFixed(3));
}

export default {
	data() {
		return {
			loginForm: {
				username: '',
				password: '',
				uuid:''
			},
			rules: {
				username: [{
					required: true,
					message: '请输入用户名',
					trigger: 'blur'
				}],
				password: [{
					required: true,
					message: '请输入密码',
					trigger: 'blur'
				}]
			},
			verifyStatus: false,
		}
	},
	mixins: [drawMixin],
	components: {
		Verify,
	},
	mounted() {
		console.log('1111');
	},
	methods: {
		submitForm(formName) {
			const self = this;
			self.$refs[formName].validate((valid) => {
				if (valid && self.verifyStatus) {
					//add code
					let random=Math.random()*(1000-1)+1;
					let uuid=`110${random}221`;
					self.loginForm.uuid=uuid;
					login(self.loginForm.username,self.loginForm.password,self.loginForm.uuid).then(resp=>{
						if(resp.code==200){
							this.$message.success('登录成功');
							// localStorage.setItem("token",resp.token);
							sessionStorage.setItem("token",resp.token);
							this.$router.push({name:'entraceWBS'});
						}else{
							this.$message.error(resp.msg);
						}
					}).catch(err=>{
						console.log(err);
					})
				} else {
					if (!self.verifyStatus) {
						this.$message.error('登录失败');
					}
					console.log('error submit!!');
					return false;
				}
			})
		},
		addfunction() {

		},
		verifySuccess() {
			console.log("成功");
			this.verifyStatus = true;
		},
		verifyError() {
			console.log("失败");
			this.verifyStatus = false;
		}
	},
	beforeRouteEnter(to, from, next) {
		next(vm => {
			// vm.$store.commit('setHeadTitle', "管理员登录");
		})
	},
	beforeRouteLeave(to, from, next) {
		next();
	},
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/utils.scss';

#login {
	width: 100%;
	height: 100vh;
	background-color: #0D1E3C;

	.bg {
		width: 100%;
		height: 100%;
		background: url('@/assets/img/login_bg.png') no-repeat;
		background-size: 100% 100%;

		.top {
			width: 100%;
			height: 60px;
			background: url('@/assets/img/top_nav.png') no-repeat;
			background-size: cover;
			background-position: 65% 0;
			overflow: hidden;

			h1 {
				text-align: center;
				color: #ffffff;
				font-size: 24px;
				letter-spacing: 0.4rem;
				font-family: '华文中宋';
			}
		}

		.loginBox {
			width: 478px;
			height: 398px;
			display: flex;
			float: right;
			margin: 10% 10vw 10% 0;
			flex-direction: column;
			align-items: center;

			.loginTitle {
				color: #ffffff;
				font-size: 30px;
				letter-spacing: 2px;
				font-family: '华文中宋';
				font-weight: 700;
				line-height: 32px;
				background-image: -webkit-linear-gradient(top, #69edff, #3bb7f5, #248ced);
				background-clip: text;
				-webkit-text-fill-color: transparent;
			}

			.login-wrap {
				height: 70%;
				-webkit-border-radius: 5px;
				border-radius: 5px;
				-moz-border-radius: 5px;
				background-clip: padding-box;
				margin-top: 20px;
				width: 478px;
				padding: 10% 35px 15px 35px;
				background: url('@/assets/img/loginWrap.png') no-repeat;
				background-size: 100% 100%;
				box-shadow: 0 0 25px #0D1E3C;


				::v-deep .el-form-item__label {
					text-align: left;
					font-size: 12px;
					font-weight: bold;
					color: rgba(105, 237, 255, 0.996078431372549);
				}

				::v-deep .el-input {
					width: 87%;

					.el-input__inner {
						border: 1px solid rgba(105, 237, 255, 0.996078431372549);
						background-color: rgb(27, 64, 92);
						color: rgba(105, 237, 255, 0.996078431372549);
					}
				}

				::v-deep .verify-bar-area {
					background-color: rgb(27, 64, 92);
					color: rgba(105, 237, 255, 0.996078431372549);
					border: 1px solid rgba(105, 237, 255, 0.996078431372549);
				}

				::v-deep .verify-left-bar .verify-msg {
					color: #409eff;
				}

				::v-deep .el-button--primary {
					background-color: #69ecfe;
					color: #2893ee;
					height: 40px;
				}
			}
		}

		.login-title {
			border-width: 0px;
			position: absolute;
			left: 1459px;
			top: 361px;
			width: 76px;
			height: 31px;
			display: flex;
			font-family: Arial, Helvetica, sans-serif;
			font-weight: 700;
			font-size: 36px;
			letter-spacing: 2px;
			color: rgba(105, 237, 255, 0.99607);

			#lh {
				position: absolute;
				align-self: flex-start;
				padding: 0;
				box-sizing: border-box;
				width: 100%;
			}
		}

		.ms-title {
			margin: -30px auto 40px auto;
			text-align: center;
			font-size: 18px;
			color: #505458;
		}

		.ms-login {
			position: absolute;
			left: 50%;
			top: 50%;
			width: 460px;
			height: 160px;
			margin: -150px 0 0 -190px;
			padding: 40px;
			border-radius: 5px;
			background: #fff;
		}

		.login-btn {
			display: flex;
			text-align: center;
			justify-content: space-between;
			align-items: flex-end;
			width: 100%;
			height: auto;

			.verify {
				position: relative;
			}
		}

		.login-btn button {
			width: 100px;
			height: 36px;
			float: left;
		}
	}
}
</style>