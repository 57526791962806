<template>
    <div>
        <Echart :options="options" id="bottomLeftChart" height="440px" width="500px">
        </Echart>
    </div>
</template>

<script>
import Echart from '@/common/echart'

export default {
    data() {
        return {
            options: {},
        };
    },
    components: {
        Echart,
    },
    props: {
        cdata: {
            type: Object,
            default: () => ({})
        }
    },
    watch: {
        cdata: {
            handler(newData) {
                this.options = {
                    color: [
                        "#f85d4a",
                        "#f85d4a",
                        "#f85d4a",
                        "#f85d4a",
                        "#f85d4a",
                        "#f85d4a",
                        "#f85d4a",
                        "#f85d4a",
                        "#f85d4a"
                    ],
                    tooltip: {
                        trigger: 'item',
                    },
                    textStyle: {
                        color: '#fff',
                    },
                    series: [
                        {
                            name: '列车风险信息',
                            type: 'pie',
                            radius: [0, '15%'],
                            avoidLabelOverlap: false,
                            label: {
                                show: true,
                                position: 'center',
                                color: '#fff',
                                fontWeight: '700'
                            },
                            emphasis: {
                                label: {
                                    show: true,
                                    fontSize: 15,
                                    fontWeight: 'bold'
                                }
                            },
                            labelLine: {
                                show: false
                            },
                            tooltip: {
                                trigger: 'item',
                                formatter:"{b}"
                            },
                            data: [
                                { value: 0, name: '城市轨迹\r\n交通' },
                            ]
                        },
                        {
                            name: '列车风险信息',
                            type: 'pie',
                            radius: ['21%', '35%'],
                            avoidLabelOverlap: false,
                            label: {
                                show: true,
                                position: 'inside',
                                color: '#fff',
                                fontWeight: '700'
                            },
                            itemStyle: {
                                borderWidth: 2,
                                borderColor: 'white'
                            },
                            emphasis: {
                                label: {
                                    show: true,
                                    fontSize: 15,
                                    fontWeight: 'bold'
                                }
                            },
                            labelLine: {
                                show: false
                            },
                            data: newData.CircleSecond
                        },
                        {
                            name: '列车风险信息',
                            type: 'pie',
                            radius: ['40%', '70%'],
                            avoidLabelOverlap: false,
                            label: {
                                show: true,
                                position: 'inside',
                                color: '#fff',
                                fontWeight: '700'
                            },
                            emphasis: {
                                label: {
                                    show: true,
                                    fontSize: 15,
                                    fontWeight: 'bold'
                                }
                            },
                            itemStyle: {
                                borderWidth: 2,
                                borderColor: 'white'
                            },
                            labelLine: {
                                show: false
                            },
                            data: newData.CircleThird
                        }
                    ]
                }
            },
            immediate: true,
            deep: true
        },
    },
}
</script>

<style></style>