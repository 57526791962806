<template>
    <div class="d-flex jc-center">
        <BottomLeftChart></BottomLeftChart>
    </div>
</template>

<script>
import BottomLeftChart from './echarts/bottom/bottomLeftChart';

export default{
    data(){
        return{

        }
    },
    components:{
        BottomLeftChart
    }
}
</script>

<style>

</style>