<template>
    <div>
        <Echart :options="options" id="bottomRightChart" height="440px" width="500px">
        </Echart>
    </div>
</template>

<script>
import Echart from '@/common/echart'

export default {
    data() {
        return {
            options: {},
        }
    },
    components: {
        Echart
    },
    props: {
        cdata: {
            type: Object,
            default: () => ({})
        }
    },
    watch: {
        cdata: {
            handler(newData) {
                this.options = {
                    xAxis: {
                        type: 'category',
                        data: newData.xAxis,
                    },
                    yAxis: {
                        type: 'value'
                    },
                    tooltip: {
                        trigger: 'item',
                    },
                    textStyle: {
                        color: '#fff',
                    },
                    series: [
                        {
                            data: newData.seriesVal,
                            type: 'bar',
                            barWidth: 30,
                            showBackground: true,
                            backgroundStyle: {
                                color: 'rgb(105, 236, 254,0.2)'
                            },
                            itemStyle: {
                                normal: {
                                    color: function (params) {
                                        var index_color = params.value;
                                        if (index_color <1) {
                                            return '#69ecfe';
                                        }else if(index_color >=1 && index_color<10){
                                            return '#fbed43';
                                        }else{
                                            return '#f95d49';
                                        }
                                    }
                                }
                            }
                        }
                    ]
                }
            },
            immediate: true,
            deep: true
        }
    }
}
</script>

<style></style>